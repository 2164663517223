<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData();
        "
      >
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Event_Name')">
                <el-input
                  v-model.trim="formInline.name"
                  filterable
                  placeholder="输入活动名称"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</AuthorityComponent
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.add']"
                type="primary"
                icon="el-icon-plus"
                @click="$router.push('/addCouponDis')"
                >添加</AuthorityComponent
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.id"
            :formatter="item.formatter"
          />
          <AuthorityComponent
            ComponentName="el-table-column"
            :permission="['button.view']"
            label="操作"
            width="70"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="showDetail(scope.$index, scope.row)">查看</el-button>
            </template>
          </AuthorityComponent>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "couponDistribute",
  data() {
    return {
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      distributeTime: "",
      tableCols: [
        {
          id: 1,
          prop: "activityName",
          label: "活动名称",
        },
        {
          id: 2,
          prop: "couponNames",
          label: "关联优惠券",
          formatter: (row, column) => {
            // if (row.couponNum == 1) {
            //     return '单张券'
            // } else if (row.couponNum > 1) {
            //     return '多张券'
            // }
            if (row.couponRuleActivities.length > 1) {
              return "多张券";
            } else {
              return "单张券";
            }
          },
        },
        {
          prop: "publishGroup",
          label: "发放类型",
          formatter: (row, column) => {
            if (row.publishGroup == 0) {
              return "按用户标签";
            } else if (row.publishGroup == 1) {
              return "按手机号";
            }
          },
        },
        {
          id: 3,
          prop: "publishAmount",
          label: "优惠券发行量",
        },
        {
          prop: "touchAmount",
          label: "触达量",
        },
        {
          prop: "usedAmount",
          label: "使用量",
        },
        {
          id: 7,
          prop: "creatorName",
          label: "发放人",
          width: "",
        },
        {
          id: 6,
          prop: "createdTime",
          label: "发放时间",
        },
      ],
      tableData: [],
      formInline: {
        name: "",
      },
    };
  },
  methods: {
    showDetail(index, obj) {
      this.$router.push({
        path: "/couponDisDetail",
        query: obj,
      });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 搜索
    searchData(pageNum) {
      this.tableData = [];
      this.loading = true;
      const tempData = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name: this.formInline.name,
      };
      const opt = {
        method: "get",
        url: "/acb/2.0/distributeActivity/list",
        data: tempData,
        success: (res) => {
          this.tableData = res.value.list;
          this.total = res.value.total * 1;
          this.loading = false;
        },
      };
      this.$request(opt);
    },
  },
  mounted() {
    this.searchData();
  },
  activated() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
